<template>
  <div>
    <!-- <button type="button" class="btn_back" @click="toHomePage"><i class="iconfont icon-arrow-left"></i> 返回大地图</button> -->
    <router-view />
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
    components: {

    },
    // 定义属性
    data() {
        return {

        }
    },
    // 计算属性，会监听依赖属性值随之变化
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        toHomePage(){
            this.$router.push("/");
            this.toThingJS("backToMap",{});
        },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    destroyed() {

    }
}
</script>

<style lang='scss'>
.btn_back{
    background: none;
    border:0 none;
    position: absolute;
    left:10px;
    top:20px;
    font-size: 1.6rem;
    font-weight: 500;
    color:#fff;
    z-index: 999;
    
    i.iconfont{
        font-size:2.4rem;
        vertical-align: top;
        display: inline-block;
    }
}
.nav-office{
    position: fixed;
    bottom:2rem;
    left:0;
    right:0;
    width:50rem;
    margin:auto;
    text-align: center;
    z-index: 999;
}
.nav-office .el-round-button{
    display: inline-block;
    margin:0 0.5rem;
    background-color: rgb(57, 95, 170);
    color:#fff;
}
.nav-office .el-round-button:focus,
.nav-office .el-round-button:hover{
    background-color: rgb(27, 55, 109);
}

.office-info{
    .office-info-wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        .office-info-item {
            flex: 1 1 auto;
            color: #fff;
            padding: 5px;
            font-size: 12px;
            position: relative;
            box-sizing: border-box;
            margin: 1px;
            width: 85.5px;
            text-align: center;
            h3 {
                font-size: 18px;
                background-image: url("../assets/images/data-bg-1.png");
                width: 78px;
                height: 78px;
                background-repeat: no-repeat;
                background-size: cover;
                line-height: 78px;
                margin: auto;
            }

            p {
                margin-top: 10px;
                color: #fff;
            }

            .bg-2 h3 {
                font-size: 14px;
                background-image: url("../assets/images/data-bg-2.png");
            }

             .bg-2 p {
                margin: 0;
            }
        }
    }
}

</style>