/**
 *
 * 过滤器定义方式:
 vue.filter('过滤器名', (value) => {
 value处理代码;
  return value
})
 */

import Vue from "vue"
// 判断字段的有无
const checktValue = Vue.filter("checktValue", (value) => {
    if (value === null || value === "" || value == undefined) {
        return "无"
    }
    return value
})
// 类型过滤
const storageAppliType = Vue.filter("storageAppliType", (value) => {
    let text = value;
    switch (value) {
        case "NEW":
            text = "新建";
            break;
        case "VETO":
            text = "否决";
            break;
        case "REVIEW":
            text = "审核中";
            break;
        case "COMPLETED":
            text = "已通过";
            break;
    }
    return text;
})
// 状态
const projectStatus = Vue.filter("projectStatus", (value) => {
    let text = value;
    switch (value) {
        case 1:
            text = "研发中";
            break;
        case 2:
            text = "计划中";
            break;
        case 3:
            text = "计划完成";
            break;
        case 4:
            text = "运行中";
            break;
        case 5:
            text = "完结";
            break;
    }
    return text;
})

const runStatus = Vue.filter("runStatus", (value) => {
    let text = value;
    switch (value) {
        case 1:
            text = "正常";
            break;
        case 2:
            text = "关机";
            break;
        case 3:
            text = "故障中";
            break;
    }
    return text;
});

const useStatus = Vue.filter("useStatus", (value) => {
    let text = value;
    switch (value) {
        case 1:
            text = "正常自用";
            break;
        case 2:
            text = "闲置";
            break;
        case 3:
            text = "待出租";
            break;
        case 4:
            text = "出租中";
            break;
        case 5:
            text = "出售中";
            break;
    }
    return text;
});

const serviceType = Vue.filter("serviceType", (value) => {
    let text = value;
    switch (value) {
        case 1:
            text = "厂家售后";
            break;
        case 2:
            text = "自行维保";
            break;
        case 3:
            text = "维保外包";
            break;
    }
    return text;
});

export default {
    checktValue,
    storageAppliType,
    projectStatus,
    runStatus,
    useStatus,
    serviceType
}