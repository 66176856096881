<template>
    <div id="app">
        <div class="loading" v-show="loading==true">
            <div class="loading-logo">
                <img src="@/assets/images/logo.gif" alt="">
                <img src="@/assets/images/logo_shadow.png" alt="">
            </div>
            <div class="loading-progress">
                <el-progress :percentage="progress" :show-text="false"></el-progress>
                <p>正在加载 {{progress}}%</p>
            </div>
           
        </div>
        <div class="layout">
            <router-view />
            <!-- <img alt="Vue logo" src="./assets/logo.png"> https://www.thingjs.com/s/23d27f135da429e780ee7dea?params=105b0f77fd24654d4eebc434e9 -->
            <iframe src="https://www.thingjs.com/s/0496b7ea3d201064bbec7431?params=105b0f77fd24654d4eebc434e9" id="3dframe" frameborder="0" scrolling="no" style="height: 100%; width:100%;" @mouseover="setFocus"></iframe>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import * as Api from "@/api";
import { MessageBox } from 'element-ui';

export default {
    name: "App",
    components: {

    },
    data() {
        return {
            loading:true,
            progress:0,
            time:0,
            timer:null
        };
    },
    computed: {},
    watch: {},
    mounted() {
        // this.getGPU();
        
        this.timeStart(this.time);
        Vue.prototype.toThingJS = function (func, params) {
            let iframe = document.getElementById("3dframe");
            iframe.contentWindow.postMessage(
                {
                    cmd: func,
                    params: params,
                },
                "*"
            );
        };
    },
    created() {
        // Promise.all([
            
        // ]);
    },
    methods: {
        timeStart(time){
            let that = this;
            this.timer = setInterval(()=>{
                if(this.loading==false){
                    clearInterval(this.timer);
                    return false;
                }
                this.time++;
                if(this.time>20 && this.time%10==0){
                    clearInterval(this.timer);
                    MessageBox.confirm('您的设备配置不满足场景显示的最低要求，无法正常使用。点击确定将关闭，您也可以继续等待。', '提示', {
                        confirmButtonText: '确定关闭',
                        cancelButtonText: '继续等待',
                        type: 'warning',
                        center:true,
                    }).then(() => {
                        //TOOD:关闭浏览器
                        this.ClosePage();
                    }).catch(() => {
                        that.timeStart(this.time);
                        //TODO:继续等待
                    });
                }
            },1000);
        },
        handleClose() { },
        fromThingJS(params) {
            console.log("ThingJS返回结果======>", params);
        },
        redirectTo(params) {
            console.log("ThingJS,触发页面跳转======>", params.url);
            this.$router.push(params.url);
        },

        showDetail(params) {
            console.log("反馈数据=====>", params);
            this.$store.dispatch("setDeviceDetail", params.target);
        },
        showWarningInfo(params) {
            this.dialogVisible = true;
        },
        setFocus() {
            let iframe = document.getElementById("3dframe");
            iframe.contentWindow.focus();
        },
        loadingProgress(params){
            this.progress = parseInt(params.progress);
            if(params.progress==100){
                setTimeout(()=>{
                    this.loading = false;
                    // MessageBox.close(false);
                },1000);
            }
        },
        getGPU(){
            const canvas = document.createElement('canvas'),
                gl = canvas.getContext('experimental-webgl'),
                debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
            const info = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
            return info;
        },
        ClosePage() {
            var userAgent = navigator.userAgent;
            if (userAgent.indexOf("MSIE") > 0) {
                if (userAgent.indexOf("MSIE 6.0") > 0) {
                    window.opener = null;
                    window.close();
                } else {
                    window.open("", "_top");
                    window.top.close();
                }
            } else if (userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("Chrome") != -1) {
                window.location.href = "about:blank "; //火狐默认状态非window.open的页面window.close是无效的
                //window.history.go(-2);
            } else {
                window.opener = null;
                window.open("about:blank", "_blank");
                window.close();
            }

        }
    },
    destroyed() { },
};
</script>

<style lang='scss'>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.4rem;
    color: #2c3e50;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .layout{
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .loading{
        width:100%;
        height:100%;
        position: fixed;
        left:0;
        top:0;
        right:0;
        bottom:0;
        background: linear-gradient(to bottom,rgba(27,55,109,1),rgba(27,55,109,0.6));
        background-color: #fff;
        z-index: 1000;

        .loading-logo{
            width:240px;
            height:300px;
            position: absolute;
            left:0;
            top:0;
            right:0;
            bottom:0;
            margin:auto;
            img{
                width:100%;
            }
            img + img{
                position: absolute;
                bottom:0;
                left:0;
            }
        }

        .loading-progress{
            width:500px;
            height:100px;
            position: absolute;
            left:0;
            right:0;
            bottom:0;
            margin:auto;
            
            p{
                text-align: center;
                color:#fff;
            }
        }
    }
}
.top-panel {
    top: 0;
    left: 0;
    right: 0;
    height: 6rem;
    color: #fff;
    h3 {
        margin: 0;
        font-size: 2rem;
    }
    small {
        text-transform: uppercase;
    }
    .title-slider {
        position: absolute;
        width: 100px;
        height: 3px;
        left: 635px;
        top: 20px;
        background-color: #fff;
        animation: title-slider-repeat 2s linear infinite alternate;
        -webkit-animation: title-slider-repeat 2s linear infinite alternate;
    }
}
.weather-panel {
    position: absolute;
    right: 20px;
    top: 0;

    .weather-info {
        display: -webkit-flex;
        display: flex;
        flex-flow: row wrap;
        margin-top: 10px;
        .weather-info-item {
            color: #fff;
            flex: 0 0 8rem;
            display: -webkit-flex;
            display: flex;
            flex-flow: row wrap;
            border-right: 1px solid rgba(255, 255, 255, 0.5);
            padding-left: 10px;
            align-items: center;
            text-shadow: 0 0 15px rgba(0, 0, 0, 0.3);

            .time {
                flex: 1 1 100%;
                font-weight: bold;
                font-size: 2.4rem;
            }
            .date {
                flex: 1 1 60%;
                font-size: 1.4rem;
            }
            .week {
                flex: 1 1 40%;
            }
            .weather-img {
                flex: 1 1 30%;
                img {
                    width: 2.2rem;
                }
            }
            .weather-value {
                flex: 1 1 70%;
                font-size: 2.4rem;
                font-weight: bold;
                small {
                    font-size: 1.2rem;
                }
            }
            .weather-desc {
                flex: 1 1 50%;
            }
            .weather-wind {
                flex: 1 1 50%;
            }
            .weather-single {
                flex: 1 1 100%;
                h3 {
                    font-size: 2.4rem;
                    margin: 0;
                }
                small {
                    font-size: 1.2rem;
                }
            }
        }
        .weather-info-item:last-child {
            border-right: 0 none;
        }
        .datetime {
            flex: 0 0 14rem;
        }
        .today-weather {
            flex: 0 0 14rem;
        }
    }
}
.warning-dialog-content {
    .warning-item {
        display: flex;
        flex-flow: row nowrap;
        padding: 6px 0;
        border-bottom: 1px solid #efefef;
        label {
            flex: 0 0 70px;
        }
        p {
            margin: 0;
        }
    }
}
</style>
