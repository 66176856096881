import Vue from 'vue'
import App from './App.vue'

import filters from '@/utils/filter.js'
Vue.use(filters);

// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

import ElementUI from "element-ui" //前端UI组件
Vue.use(ElementUI) //使用前端UI组件


import "./assets/scss/main.scss" //全局css样式
import "./assets/scss/custom-animate.scss" //全局动画
import "element-ui/lib/theme-chalk/index.css";

// import "./assets/icon/iconfont.css";

import animated from 'animate.css' //动画库
Vue.use(animated)

import 'video.js/dist/video-js.css'
// import Video from 'video.js'
// import "videojs-contrib-hls"
// Vue.prototype.$video = Video //引入Video播放器

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper,);

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import moment from 'moment';
moment.locale('zh-cn');
Vue.prototype.$moment = moment;

import router from "./router" //路由定义
import store from "./store" //vuex状态1

Vue.config.productionTip = false


new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app')