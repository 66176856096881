<template>
    <div class="detail-box-wrapper" v-drag>
        <div class="detail-box-inner">
            <div class="detail-box-title" id="detailBoxTitle">
                {{obj.name}}
                <button type="button" class="detail-box-close" @click="handleCloseDetailbox"><i class="iconfont icon-close"></i></button>
            </div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane name="first" :class="activeName=='first'?'is-actived':''">
                    <span slot="label" title="基本信息"><i class="iconfont icon-default"></i></span>
                    <h4>基本信息</h4>
                    <div class="detail-box-content">
                        <el-scrollbar>
                            <div v-if="isCamera"><video ref="camera" :id="'video-detail_'+obj.id" class="video-js" muted></video></div>
                            <div class="detail-info-wrapper">
                                <el-descriptions :column="1" border colon size="small" content-class-name="base-detail-info">
                                    <el-descriptions-item label="设备名称" v-if="obj.hasOwnProperty('name')">{{obj.name}}</el-descriptions-item>
                                    <el-descriptions-item label="设备品牌" v-if="obj.hasOwnProperty('brand')">{{obj.brand}}</el-descriptions-item>
                                    <el-descriptions-item label="设备型号" v-if="obj.hasOwnProperty('marking')">{{obj.marking}}</el-descriptions-item>
                                    <el-descriptions-item label="安装区域" v-if="obj.hasOwnProperty('area')">{{obj.area}}</el-descriptions-item>
                                    <el-descriptions-item label="经纬度" v-if="obj.hasOwnProperty('longitude')">{{obj.longitude}},{{obj.latitude}}</el-descriptions-item>
                                    <el-descriptions-item label="安装位置" v-if="obj.hasOwnProperty('address')">{{obj.address}}</el-descriptions-item>
                                    <el-descriptions-item label="负责人" v-if="obj.hasOwnProperty('linkUser')">{{linkUserName}}</el-descriptions-item>
                                    <el-descriptions-item label="生产日期" v-if="obj.hasOwnProperty('outTime')">{{obj.outTime}}</el-descriptions-item>
                                    <el-descriptions-item label="安装日期" v-if="obj.hasOwnProperty('installTime')">{{obj.installTime}}</el-descriptions-item>
                                    <el-descriptions-item label="售后截止日期" v-if="obj.hasOwnProperty('serviceTime')">{{obj.serviceTime}}</el-descriptions-item>
                                    <el-descriptions-item label="生产厂商" v-if="obj.hasOwnProperty('firm')">{{obj.firm}}</el-descriptions-item>
                                    <el-descriptions-item label="运行状态" v-if="obj.hasOwnProperty('runStatus')">{{obj.runStatus|runStatus}}</el-descriptions-item>
                                    <el-descriptions-item label="使用状态" v-if="obj.hasOwnProperty('useStatus')">{{obj.useStatus|useStatus}}</el-descriptions-item>
                                    <el-descriptions-item label="保养周期" v-if="obj.hasOwnProperty('maintain')">{{obj.maintain}}日/次</el-descriptions-item>
                                    <el-descriptions-item label="维保单位" v-if="obj.hasOwnProperty('serviceType')">{{obj.construct|serviceType}}</el-descriptions-item>
                                    <el-descriptions-item label="3D模型" v-if="obj.hasOwnProperty('model3dId')">{{obj.model3dId}}</el-descriptions-item>
                                </el-descriptions>
                                <div class="detail-info-active"></div>
                            </div>
                        </el-scrollbar>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="能耗信息" name="second"  :class="activeName=='second'?'is-actived':''" v-if="energyInfo">
                    <span slot="label"><i class="iconfont icon-overview"></i></span>
                    <h4>能耗信息</h4>
                    <div class="detail-box-content">
                    </div>
                </el-tab-pane>
                <el-tab-pane label="生产记录" name="third" :class="activeName=='third'?'is-actived':''" v-if="listCapacityData.totalCount>0">
                    <span slot="label"><i class="iconfont icon-shengchan"></i></span>
                    <h4>生产记录</h4>
                    <div class="detail-box-content"></div>
                </el-tab-pane>
                <el-tab-pane label="运行记录" name="fourth" :class="activeName=='fourth'?'is-actived':''">
                    <span slot="label"><i class="iconfont icon-kaishi_yunhang"></i></span>
                    <h4>运行记录</h4>
                    <div class="detail-box-content">

                    </div>
                </el-tab-pane>
                <el-tab-pane label="运维记录" name="five" :class="activeName=='five'?'is-actived':''">
                    <span slot="label"><i class="iconfont icon-yunweiguanli_1"></i></span>
                    <h4>运维记录</h4>
                    <div class="detail-box-content"></div>
                </el-tab-pane>
                <el-tab-pane label="关联表计" name="six" :class="activeName=='six'?'is-actived':''">
                    <span slot="label"><i class="iconfont icon-yiqiyibiao"></i></span>
                    <h4>关联表计</h4>
                    <div class="detail-box-content"></div>
                </el-tab-pane>
                <!-- <el-tab-pane label="报警信息" name="seven">
                    <span slot="label"><i class="iconfont icon-warning"></i></span>
                    <h4>报警信息</h4>
                    <div class="detail-box-content">
                        <el-scrollbar>
                            <el-descriptions :column="1" border colon class="danger-description">
                                <el-descriptions-item label="报警级别">3</el-descriptions-item>
                                <el-descriptions-item label="报警信息">电压异常</el-descriptions-item>
                                <el-descriptions-item label="报警时间">2022/03/31 14:22:00</el-descriptions-item>
                            </el-descriptions>
                        </el-scrollbar>
                    </div>
                </el-tab-pane> -->
            </el-tabs>
        </div>
    </div>
</template>

<script>
import videojs, { log } from "video.js";
import "videojs-contrib-hls";
import $ from 'jquery'

import * as Api from '@/api'
export default {
    name: 'DetailBox',
    props: {
        active: {
            type: String,
            default: 'first'
        },
        visible: Boolean,
        dataSource: Object
    },
    components: {

    },
    // 定义属性
    data () {
        return {
            activeName: this.active,
            obj: {},
            users: [],
            energyInfo: {},
            listCapacityData: {
                currPage: 1,
                list: [],
                pageSize: 20,
                totalCount: 0,
                totalPage: 0
            },
            runRecords: {
                currPage: 1,
                list: [],
                pageSize: 20,
                totalCount: 0,
                totalPage: 0
            },
            devOpsRecords: {
                currPage: 1,
                list: [],
                pageSize: 20,
                totalCount: 0,
                totalPage: 0
            },
            relationMeters: [],
            videoOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
                autoplay: true, //如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: true, // 视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                flash: {
                    hls: { withCredentials: false },
                },
                html5: { hls: { withCredentials: false } },

                sources: [
                    {
                        type: "application/x-mpegURL",
                        src: "http://ivi.bupt.edu.cn/hls/cctv5phd.m3u8",
                    },
                ],
                poster: "", //你的封面地址
                width: "100%",
                height: "100%",
                notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, //当前时间和持续时间的分隔符
                    durationDisplay: true, //显示持续时间
                    remainingTimeDisplay: false, //是否显示剩余时间功能
                    fullscreenToggle: true, //全屏按钮
                },
            },
            videoPlay: null,
            isCamera: false,
            timer:null
        }
    },
    // 计算属性，会监听依赖属性值随之变化
    computed: {
        linkUserName () {
            var target = this.$parent.users.filter(user => {
                return user.userId == this.obj.linkUser
            })
            return target[0].name;
        }
    },
    // 监控data中的数据变化
    watch: {
        dataSource (newVal) {
            this.obj = newVal;
            this.loadData(newVal);
        }
    },
    // 方法集合
    methods: {
        initVideo (vidoeUrl) {
            // 这些options属性也可直接设置在video标签上，见 muted
            let options = {
                autoplay: true, // 设置自动播放
                controls: true, // 显示播放的控件
                sources: [
                    // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
                    {
                        src: vidoeUrl,
                        type: "application/x-mpegURL", // 告诉videojs,这是一个hls流
                    },
                ],
            };
            // videojs的第一个参数表示的是，文档中video的id
            this.videoPlay = videojs('video-detail_' + this.obj.id, options, function onPlayerReady () {
                this.play();
            });
        },
        handleClick (tab, event) {
            console.log("切换选项卡========》");
            clearInterval(this.timer);
            this.$nextTick(()=>{
                $(".detail-info-active").css({top:0});
                this.loadInfoAnimate();
            });
        },
        handleCloseDetailbox () {
            if (this.videoPlay) {
                this.videoPlay.dispose();
                this.videoPlay = null;
            }

            this.$emit("update:visible", false);
        },
        getEnergyInfo (deviceId) {
            Api.infoEnergy(deviceId).then(resp => {
                this.energyInfo = resp.data;
            });
        },
        getCapacities (deviceId) {
            Api.listCapacities({ rId: deviceId, limit: this.listCapacityData.pageSize, page: this.listCapacityData.currPage, rType: 1 }).then(resp => {
                this.listCapacityData = resp.data;
            })
        },
        getRunRecords (deviceId) {
            Api.listRunRecords({ productDevId: deviceId, limit: this.listCapacityData.pageSize, page: this.listCapacityData.currPage }).then(resp => {
                this.runRecords = resp.data;
            });
        },
        getDevOpsRecords (deviceId) {
            Api.listDevOpsRecords({ devId: deviceId, limit: this.listCapacityData.pageSize, page: this.listCapacityData.currPage }).then(resp => {
                this.devOpsRecords = resp.data;
            });
        },
        getRelationmeter (deviceId) {
            Api.listRelationMeter(deviceId).then(resp => {
                if (resp.data.length > 0) {
                    this.relationMeters = resp.data[0].devList;
                }
            });
        },
        loadData () {
            let that = this;
            // this.obj = value;
            this.getEnergyInfo(this.obj.id);
            this.getCapacities(this.obj.id);
            this.getRunRecords(this.obj.id);
            this.getDevOpsRecords(this.obj.id);
            this.getRelationmeter(this.obj.id);

            if (this.obj.modeId == '1422084265290870791') {
                this.isCamera = true;
                Api.getCameraPreviewUrl(this.obj.firm).then(resp => {
                    that.initVideo(resp.data.data.url);
                });
            } else {
                this.isCamera = false;
            }
        },
        loadInfoAnimate(){
            var infoLen = $(".is-actived").find(".el-descriptions-row").length;
            var i=0;
            var activeBar = $(".detail-info-active");
            if(infoLen>0){
                this.timer = setInterval(()=>{
                    if(i<infoLen){
                        activeBar.css({top:35*i+"px"});
                        i++;
                    }else{
                        i=0;
                    }  
                },2000);
            }
            
        }
    },
    directives: {
        drag: {
            // 指令的定义
            bind: function (el) {
                var title = el.childNodes[0].childNodes[0];
                title.style.cursor = "move";
            },
            inserted: function (el) {
                var handle = el.childNodes[0].childNodes[0];
                handle.onmousedown = function (e) {
                    var distX = e.pageX - el.offsetLeft;
                    var distY = e.pageY - el.offsetTop;
                    // console.log("元素本身的高：" + el.clientHeight + ",元素本身的宽：" + el.clientWidth)

                    if (e.preventDefault) {
                        e.preventDefault();
                    } else {
                        e.returnValue = false;
                    }; //解决快速拖动滞后问题

                    document.onmousemove = function (e) {

                        // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                        let left = e.clientX - distX;
                        let top = e.clientY - distY;

                        if (left <= 0) {
                            left = 5; //设置成5是为了不离边缘太近
                        } else if (left > document.documentElement.clientWidth - el.clientWidth) {
                            //document.documentElement.clientWidth 屏幕的可视宽度
                            left = document.documentElement.clientWidth - el.clientWidth - 5
                        }
                        if (top <= 0) {
                            top = 5;
                        } else if (top > document.documentElement.clientHeight - el.clientHeight) {
                            top = document.documentElement.clientHeight - el.clientHeight - 5
                        }
                        el.style.left = left + 'px';
                        el.style.top = top + 'px';
                    }
                    document.onmouseup = function () {
                        document.onmousemove = document.onmouseup = null;
                    }
                }
            },
        },

    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created () {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted () {
        this.obj = this.dataSource;
        this.loadData(this.dataSource);
        

        this.$nextTick(()=>{
            this.loadInfoAnimate();
        });
    },
    beforeCreate () { }, // 生命周期 - 创建之前
    beforeMount () { }, // 生命周期 - 挂载之前
    beforeUpdate () { }, // 生命周期 - 更新之前
    updated () { }, // 生命周期 - 更新之后
    beforeDestroy () { }, // 生命周期 - 销毁之前
    destroyed () {
        if (this.videoPlay) {
            this.videoPlay.dispose();
        }
    }, // 生命周期 - 销毁完成
    activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang='scss' scoped>
.detail-box-wrapper {
    position: absolute;
    height: 720px;
    width: 330px;
    background-color: rgba(27, 55, 109, 0.7);
    border-radius: 0;
    padding: 5px;
    right: 20px;
    top: 225px;

    .detail-box-inner {
        width: 100%;
        height: 100%;
        // border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 0;

        .detail-box-title {
            padding: 10px;
            background-color: rgba(0, 0, 0, 0.6);
            color: #fff;
            position: relative;

            .detail-box-close {
                position: absolute;
                right: 5px;
                top: 7px;
                font-size: 18px;
                background-color: transparent;
                color: #fff;
                border: 0 none;
                &:hover {
                    color: #d8dcf3;
                }
            }
        }

        /deep/ .el-tabs {
            .el-tabs__header {
                margin: 0;
                .el-tabs__nav-wrap::after{
                    background-color: transparent;
                }
                .el-tabs__nav {
                    .el-tabs__item {
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        padding: 0;
                        text-align: center;
                            color:#fff;
                        
                        &.is-active{
                           color: #409EFF
                        }
                        i {
                            font-size: 22px;
                        }
                    }
                }
            }

            .el-tab-pane {
                
                h4 {
                    background-color: rgba(0, 0, 0, 0.2);
                    margin: 0;
                    padding: 10px;
                    color:#fff;
                    // border-top: 1px solid #d8dcf3;
                    // border-bottom: 1px solid #d8dcf3;
                }
                .detail-box-content {
                    height: 578px;
                    color: #fff;
                    .el-scrollbar {
                        height: 100%;
                    }
                    .el-scrollbar__wrap {
                        overflow-x: hidden;

                        .detail-info-wrapper{
                            position: relative;

                            .detail-info-active{
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                height: 34.5px;
                                background: rgba(135, 197, 255, 0.2);
                                transition: all 0.2s;
                            }
                        }
                    }
                }
            }
        }
    }
}
.video-js {
    width: 100%;
    height: 180px;
}
/deep/ .el-descriptions {
    
    .el-descriptions__body {
        background: none;
        color:#fff;
    }
    .is-bordered {
        .el-descriptions-item__cell {
            padding: 8px 10px;
            border:0 none;
            height:35px;
            border-bottom: 1px solid rgba(255,255,255, 0.1);
        }
        th {
            text-align: right;
            border-right: 1px solid rgba(255,255,255, 0.1);
        }
    }
    .el-descriptions-item__label.is-bordered-label {
        background: none;
    }
    .el-descriptions-item__label {
        color: #ededed;
        font-weight: bold;
    }
}

.danger-description /deep/ .el-descriptions-item__content {
    color: #eb4444;
}
</style>