import { treeDeviceModel, listDevices } from '@/api'
import { reject } from 'lodash';

const user = {
    state: {
        modelTree: [],
        list: []
    },

    mutations: {
        SET_MODEL_TREE: (state, modelTree) => {
            state.modelTree = modelTree
        },
        SET_DEVICE_LIST: (state, list) => {
            state.list = list;
        }
    },

    actions: {
        GetDeviceModel({ commit }, loginInfo) {
            return new Promise((resolve, reject) => {
                treeDeviceModel().then(res => {
                    console.log("读取数据分类数据========>", res);
                    let data = res.data
                    commit('SET_MODEL_TREE', data);
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GetDeviceList({ commit }, params) {
            return new Promise((resolve, reject) => {
                listDevices(params).then(res => {
                    commit('SET_DEVICE_LIST', res.data.list);
                    resolve()
                }).catch(error => {
                    reject(error)
                });
            });
        }
    }
}

export default user
