<template>
    <div class="warn-message__alert" :class="alertType" v-if="open">
        <div class="warn-message__wrapper">
            <div class="warn-message__header">
                <h4><i class="iconfont icon-danger"></i> {{alertType=='warning'?'警告':'报警'}}信息</h4>
                <button type="button" class="warn-message__close" @click="handleClose"><i class="iconfont icon-close"></i></button>
            </div>
            <div class="warn-message__body">
                <div class="warn_message__content">
                    <slot></slot>
                </div>
            </div>
        </div>
        <!-- <audio autoplay loop v-if="open">
            <source src="/media/warning.wav" type="audio/x-wav">
            您的浏览器不支持 audio 元素。
        </audio> -->
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import { mapGetters } from 'vuex'
export default {
    name: 'WarnMessage',
    props: {
        type: {
            type: String,
            default: "warning"
        }
    },
    components: {

    },
    // 定义属性
    data() {
        return {
            alertType: this.type,
            open: true
        }
    },
    // 计算属性，会监听依赖属性值随之变化
    computed: {
        ...mapGetters(["warning"]),
    },
    // 监控data中的数据变化
    watch: {

    },
    // 方法集合
    methods: {
        handleClose() {
            this.open = false;
        },

    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang='scss' scoped>
.warn-message__alert {
    width: 250px;
    height: 120px;
    background-color: #e8ebfc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 10px;
    .warn-message__wrapper {
        width: 100%;
        height: 100%;
        .warn-message__header {
            position: relative;
            background-size: 24px;
            border-radius: 4px 4px 0 0;
            padding: 10px;
            color: #fff;
            h4 {
                margin: 0;
                font-size: 15px;
                i {
                    font-size: 20px;
                    vertical-align: bottom;
                }
            }
            .warn-message__close {
                border: 0 none;
                background-color: transparent;
                position: absolute;
                right: 5px;
                top: 5px;
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                color: #fff;
                cursor: pointer;

                &:hover {
                    color: #222;
                }
            }
        }
        .warn-message__body {
            .warn_message__content {
                width: 100%;
                padding: 10px;
                color: #222;
                font-size: 14px;
            }
        }
        .warn-message__footer {
            flex: 0 0 16rem;
            text-align: center;

            .warn-message__button {
                width: 15rem;
                background: none;
                border: 0.1rem solid #fde34d;
                color: #fff;
                font-size: 1.4rem;
                height: 4rem;
                line-height: 3.6rem;
                margin: 2rem auto 5rem auto;
                transition: all 0.2s;
            }

            .warn-message__button + .warn-message__button {
                margin-left: 10px;
            }
        }
    }
}
.warn-message__alert.warning {
    .warn-message__wrapper {
        .warn-message__header {
            background-color: #f7a23a;
        }
    }
}

.warn-message__alert.danger {
    // background-color: rgba($color: #fde34d, $alpha: 0.6);
    .warn-message__wrapper {
        .warn-message__header {
            background-color: #eb4444;
        }
    }
}
</style>