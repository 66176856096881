import { login } from '@/api'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { reject } from 'lodash';

const user = {
    state: {
        companyId: "",
        role: "",
        phone: "",
        company: [],
        userId: "",
        gesture: null,
        token: getToken(),
        username: '',
    },

    mutations: {
        SET_COMPANYID: (state, companyId) => {
            state.companyId = companyId
        },
        SET_ROLE: (state, role) => {
            state.role = role
        },
        SET_PHONE: (state, phone) => {
            state.phone = phone
        },
        SET_COMPANY: (state, company) => {
            state.company = company
        },

        SET_USERID: (state, userId) => {
            state.userId = userId
        },
        SET_GESTURE: (state, gesture) => {
            state.gesture = gesture
        },
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_USERNAME: (state, username) => {
            state.username = username
        }
    },

    actions: {
        // 登录
        PwdLogin({ commit }, loginInfo) {
            return new Promise((resolve, reject) => {
                login(loginInfo).then(res => {
                    let data = res.data
                    setToken(data.token)
                    commit('SET_COMPANYID', data.companyId);
                    commit('SET_ROLE', data.role);
                    commit('SET_PHONE', data.phone);
                    commit('SET_COMPANY', data.company);
                    commit('SET_USERID', data.userId);
                    commit('SET_GESTURE', data.gesture);
                    commit('SET_TOKEN', data.token)
                    commit('SET_USERNAME', data.username)

                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}

export default user
