<template>
    <div>
        <div class="warning-messages">
            <!-- <warn-message type="warning">
                数量达科技福利卡决胜巅峰立刻就爱上了的看法
            </warn-message>
            <warn-message type="danger">
                数量达科技福利卡决胜巅峰立刻就爱上了的看法2
            </warn-message> -->
        </div>
        <div class="module-filter h-line">
            <ui-box height="80px" title="房间列表" icon="iconfont icon-guanxian">
                <div class="module-item">
                    <div class="module-item-wrapper">
                        <el-checkbox v-model="checkAll.pipe" label="pipe" @change="handleCheckAllChange('pipe')" class="w4">全部线路</el-checkbox>
                        <el-checkbox-group v-model="checkedItems.pipe" @change="handleCheckedItemsChange('pipe')">
                            <el-checkbox label="pipe_wire">电路</el-checkbox>
                            <el-checkbox label="pipe_water">水路</el-checkbox>
                            <el-checkbox label="pipe_gas">燃气</el-checkbox>
                            <el-checkbox label="pipe_oil">燃油</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </ui-box>
            <ui-box height="250px" title="安全设施" icon="iconfont icon-anquan">
                <div class="module-item">
                    <div class="module-item-wrapper">
                        <el-checkbox v-model="checkAll.check" label="check" @change="handleCheckAllChange('check')" class="w6">全部检测设施</el-checkbox>
                        <el-checkbox-group v-model="checkedItems.check" @change="handleCheckedItemsChange('check')">
                            <el-checkbox label="check_wire" class="w4">电路检测</el-checkbox>
                            <el-checkbox label="check_smoke" class="w4">烟雾检测</el-checkbox>
                            <el-checkbox label="check_temp" class="w4">温度检测</el-checkbox>
                            <el-checkbox label="check_fire" class="w4">火点检测</el-checkbox>
                        </el-checkbox-group>
                    </div>
                    <div class="devider"></div>
                    <div class="module-item-wrapper">

                        <el-checkbox v-model="checkAll.saft" label="saft" @change="handleCheckAllChange('saft')" class="w6">全部应急设备</el-checkbox>
                        <el-checkbox-group v-model="checkedItems.saft" @change="handleCheckedItemsChange('saft')">
                            <el-checkbox label="saft_alarm">报警器</el-checkbox>
                            <el-checkbox label="saft_hydrant">消防栓</el-checkbox>
                            <el-checkbox label="saft_exting">灭火器</el-checkbox>
                            <el-checkbox label="saft_sprinkler">喷淋头</el-checkbox>
                        </el-checkbox-group>
                    </div>
                    <div class="devider"></div>
                    <div class="module-item-wrapper">

                        <el-checkbox v-model="checkAll.route" label="route" @change="handleCheckAllChange('route')" class="w6">全部应急线路</el-checkbox>
                        <el-checkbox-group v-model="checkedItems.route" @change="handleCheckedItemsChange('route')">
                            <el-checkbox label="route_escape" class="w4">逃生线路</el-checkbox>
                            <el-checkbox label="route_cannel" class="w4">消防通道</el-checkbox>
                            <el-checkbox label="route_save" class="w4">救援通道</el-checkbox>
                            <el-checkbox label="route_goods" class="w4">物资通道</el-checkbox>
                        </el-checkbox-group>
                    </div>
                    <div class="devider"></div>
                    <div class="module-item-wrapper">
                        <el-checkbox v-model="checkAll.camera" label="camera" @change="handleCheckAllChange('camera')" class="w6">全部视频监控</el-checkbox>
                        <el-checkbox-group v-model="checkedItems.camera" @change="handleCheckedItemsChange('camera')">
                            <el-checkbox label="外墙">室外</el-checkbox>
                            <el-checkbox label="一楼">1楼</el-checkbox>
                            <el-checkbox label="二楼">2楼</el-checkbox>
                            <el-checkbox label="三楼">3楼</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </ui-box>
            <ui-box height="80px" title="设备管理" icon="iconfont icon-device" style="margin-bottom:0;">
                <div class="module-item">
                    <div class="module-item-wrapper">
                        <el-checkbox v-model="checkAll.device" label="device" @change="handleCheckAllChange('device')" class="w4">全部设备</el-checkbox>
                        <el-checkbox-group v-model="checkedItems.device" @change="handleCheckedItemsChange('device')">
                            <el-checkbox v-for="item in deviceOptions" :key="item.id" :label="item" class="w4">{{item.name}}</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </ui-box>
        </div>
        <div class="energy-info" v-if="showEnergyInfo">
            <el-button class="btn__energy-close" icon="el-icon-close" @click="showEnergyInfo=false"></el-button>
            <el-tabs v-model="activeEnergy" @tab-click="handleEnergyChange">
                <el-tab-pane label="电" name="wire">
                    <div class="energy-record">
                        <div class="energy-record-item">
                            <h3>12,328</h3>
                            <p>本月用电数(kw)</p>
                        </div>
                        <div class="energy-record-item">
                            <h3>13,328</h3>
                            <p>上月用电数(kw)</p>
                        </div>
                        <div class="energy-record-item">
                            <h3>163,280</h3>
                            <p>本年总用电(kw)</p>
                        </div>
                        <div class="energy-record-item">
                            <h3>131,450</h3>
                            <p>去年总用电(kw)</p>
                        </div>
                    </div>
                    <div class="energy-chart">
                        <div id="energyConsumeChart" style="height: 100%"></div>
                    </div>
                    <div class="energy-chart">
                        <div id="energyUsedChart" style="height: 100%"></div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="水" name="water">
                    <div class="energy-record">
                        <div class="energy-record-item">
                            <h3>72</h3>
                            <p>本月用水量(吨)</p>
                        </div>
                        <div class="energy-record-item">
                            <h3>58</h3>
                            <p>上月用水量(吨)</p>
                        </div>
                        <div class="energy-record-item">
                            <h3>43</h3>
                            <p>本年月均用水(吨)</p>
                        </div>
                        <div class="energy-record-item">
                            <h3>51</h3>
                            <p>去年月均用水(吨)</p>
                        </div>
                    </div>
                    <!-- <div class="energy-chart">
                        <div id="energyConsumeChart" style="height: 100%"></div>
                    </div>
                    <div class="energy-chart">
                        <div id="energyUsedChart" style="height: 100%"></div>
                    </div> -->
                </el-tab-pane>
                <el-tab-pane label="气" name="gas">
                    <div class="energy-record">
                        <div class="energy-record-item">
                            <h3>0</h3>
                            <p>本月用气量(方)</p>
                        </div>
                        <div class="energy-record-item">
                            <h3>0</h3>
                            <p>上月用气量(方)</p>
                        </div>
                        <div class="energy-record-item">
                            <h3>0</h3>
                            <p>本年月均用气(方)</p>
                        </div>
                        <div class="energy-record-item">
                            <h3>0</h3>
                            <p>去年月均用气(方)</p>
                        </div>
                    </div>

                </el-tab-pane>
                <!-- <el-tab-pane label="油" name="oil">油</el-tab-pane> -->
            </el-tabs>
        </div>
        <div class="scene-control">
            <div class="scene-item">
                <h4><i class="iconfont icon-shangyiyiceng"></i> 楼层</h4>
                <div class="scene-item-wrapper">
                    <el-checkbox label="f1" v-model="selectFloor.xbgs_1f" @change="handleChangeFloor('xbgs_1f')">1楼</el-checkbox>
                    <el-checkbox label="f2" v-model="selectFloor.xbgs_2f" @change="handleChangeFloor('xbgs_2f')">2楼</el-checkbox>
                    <el-checkbox label="f3" v-model="selectFloor.xbgs_3f" @change="handleChangeFloor('xbgs_3f')">3楼</el-checkbox>
                    <el-checkbox v-model="expandFloors" label="route" @change="handleExpandFloors">展开</el-checkbox>
                    <el-checkbox v-model="screenfull" label="route" @change="handleScreenFull">全屏</el-checkbox>
                    <!-- <el-button  @click="$parent.ClosePage">关闭</el-button> -->
                </div>
            </div>
            <div class="scene-item">
                <h4><i class="iconfont icon-richu1"></i> 环境</h4>
                <div class="scene-item-wrapper">
                    <el-checkbox label="下雨" v-model="weather.qing" @change="handleChangeWeather('qing')">晴天</el-checkbox>
                    <el-checkbox label="下雨" v-model="weather.rain" @change="handleChangeWeather('rain')">下雨</el-checkbox>
                    <el-checkbox label="下雪" v-model="weather.snow" @change="handleChangeWeather('snow')">下雪</el-checkbox>
                    <div class="devider"></div>
                    <el-checkbox label="白天" v-model="sceneState.day" @change="handleChangeSceneState('day')">白天</el-checkbox>
                    <el-checkbox label="夜晚" v-model="sceneState.night" @change="handleChangeSceneState('night')">夜晚</el-checkbox>
                    <el-checkbox label="时间" v-model="sceneState.time" @change="handleChangeSceneState('time')">时间</el-checkbox>
                    <div class="time-slider" v-show="sceneState.time">
                        <el-slider v-model="cur_time" :min="1" :max="24" @change="handleTimeChange"></el-slider>
                    </div>
                </div>
            </div>
            <div class="scene-item">
                <h4><i class="iconfont icon-shujuku"></i> 模拟数据</h4>
                <div class="scene-item-wrapper">
                    <el-checkbox v-model="exception.route" label="route" @change="handleExceptionDemo('route')">线路异常</el-checkbox>
                    <el-checkbox v-model="exception.fire" label="fire" @change="handleExceptionDemo('fire')">消防异常</el-checkbox>
                    <el-checkbox v-model="exception.device" label="device" @change="handleExceptionDemo('device')">设备异常</el-checkbox>
                </div>
            </div>
        </div>
        <!-- <detail-box v-if="showDetailBox" :open.sync="showDetailBox" active="seven" :dataSource="selectDeviceData" style="left: 400px; top: 60px;"></detail-box>
        <detail-box v-if="showDetailBox2" :open.sync="showDetailBox2" active="seven" :dataSource="selectDeviceData" style="right:20px; bottom:20px;"></detail-box> -->
        <detail-box v-if="showDeviceDetail" ref="detailbox" :visible.sync="showDeviceDetail" active="first" :dataSource="selectDeviceData"></detail-box>
        <div class="video-swipper" v-if="showCameraList">
            <div class="custom-button-prev"><i class="iconfont icon-arrow-left"></i></div>
            <swiper ref="videoSwiper" :options="swiperOptions">
                <swiper-slide v-for="camera in cameraData" :key="camera.id">
                    <video ref="camera" :id="'video_'+camera.id" class="video-js" muted></video>
                </swiper-slide>
            </swiper>
            <div class="custom-button-next"><i class="iconfont icon-arrow-right"></i></div>
        </div>
        <device-list :dataSource="filterDeviceData" ref="detaillist" class="h-line-list" v-if="showDeviceList"></device-list>

    </div>
</template>

<script>
import DetailBox from "@/components/DetailBox";
import WarnMessage from "@/components/WarnMessage";
import DeviceList from "@/components/DeviceList";
import UiPanel from "@/components/UIPanel";
import UiBox from "@/components/UIBox";
import { mapGetters, mapState } from "vuex";
import * as Api from "@/api";
import screenfull from 'screenfull'
import videojs, { log } from "video.js";
import "videojs-contrib-hls";

export default {
    components: {
        DetailBox,
        WarnMessage,
        DeviceList,
        UiPanel,
        UiBox
    },
    // 定义属性
    data () {
        return {
            activeEnergy: "wire",
            showEnergyInfo: false,
            cur_checkall: "",
            checkAll: {
                pipe: false,
                check: false,
                saft: false,
                route: false,
                camera: false,
                device: false,
            },
            screenfull: false,
            pipeOptions: ["pipe_wire", "pipe_water", "pipe_gas", "pipe_oil"],
            checkOptions: ["check_wire", "check_smoke", "check_temp", "check_fire"],
            saftOptions: ["saft_alarm", "saft_hydrant", "saft_exting", "saft_sprinkler"],
            routeOptions: ["route_escape", "route_cannel", "route_save", "route_goods"],
            cameraOptions: ["外墙", "一楼", "二楼", "三楼"],
            deviceOptions: [],
            checkedItems: {
                pipe: [],
                check: [],
                saft: [],
                route: [],
                camera: [],
                device: [],
            },
            isIndeterminate: false,
            cur_scene: "",
            sceneState: {
                day: false,
                night: false,
                time: true
            },
            cur_weather: "",
            weather: {
                qing: true,
                rain: false,
                snow: false,
            },
            cur_time: new Date().getHours(),
            selectFloor: {
                xbgs_1f:true,
                xbgs_2f:true,
                xbgs_3f:true
            },
            exceptionType: "",
            exception: {
                route: false,
                fire: false,
                device: false
            },
            showDetailBox: false,
            showDetailBox2: false,
            showDeviceDetail: false,
            showCameraList: false,
            cameraData: [],
            swiperOptions: {
                height: 120,
                slidesPerView: 4,
                spaceBetween: 10,
                direction: "horizontal",
                autoplay: false,
                loop: false, //无缝轮播
                mousewheel: true,
                effect: "slide", //切换效果
                // pagination: {
                //     //显示页码
                //     el: ".swiper-pagination",
                //     clickable: true,
                // },
                //左右按钮
                navigation: {
                    nextEl: ".custom-button-next",
                    prevEl: ".custom-button-prev",
                },
            },
            dialogVisible: false,
            expressionDemoCheck: false,
            expandFloors: false,
            showDeviceList: false,
            listDeviceData: [],
            selectDeviceModelIds: [],
            filterDeviceData: [],
            selectDeviceData: null,
            current: "",
            current_sub: [],
            current_camera: "",
            current_device: "",
            timer: null,
            users: [],
            videoOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
                autoplay: true, //如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: true, // 视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                flash: {
                    hls: { withCredentials: false },
                },
                html5: { hls: { withCredentials: false } },

                sources: [
                    {
                        type: "application/x-mpegURL",
                        src: "http://ivi.bupt.edu.cn/hls/cctv5phd.m3u8",
                    },
                ],
                poster: "", //你的封面地址
                width: "100%",
                height: "100%",
                notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, //当前时间和持续时间的分隔符
                    durationDisplay: true, //显示持续时间
                    remainingTimeDisplay: false, //是否显示剩余时间功能
                    fullscreenToggle: true, //全屏按钮
                },
            },
            videos: [],
        };
    },
    // 计算属性，会监听依赖属性值随之变化
    computed: {
        ...mapGetters(["token", "modelTree"]),
        swiper () {
            return this.$refs.videoSwiper.$swiper;
        },
    },
    // 监控data中的数据变化
    watch: {


    },
    // 方法集合
    methods: {
        handleScreenFull(){
            console.log(screenfull.isEnabled);
            if (!screenfull.isEnabled) {
                this.$message("您的浏览器不能全屏");
                return false;
            }
            screenfull.toggle();
            // this.$message.success("全屏啦");
        },
        initVideo (videoId, vidoeUrl) {
            var player = this.videos.filter(item => {
                return item.id == "video_" + videoId;
            })[0];
            if (typeof (player) != "undefined") {
                player.play();
            } else {
                // 这些options属性也可直接设置在video标签上，见 muted
                let options = {
                    autoplay: true, // 设置自动播放
                    controls: true, // 显示播放的控件
                    sources: [
                        // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
                        {
                            src: vidoeUrl,
                            type: "application/x-mpegURL", // 告诉videojs,这是一个hls流
                        },
                    ],
                };
                // videojs的第一个参数表示的是，文档中video的id
                player = videojs(videoId, options, function onPlayerReady () {

                });
                this.videos.push(player);
            }
        },
        handleEnergyChange () {

        },
        chartEnergyConsume () {
            let energyConsumeChart = this.$echarts.init(
                document.getElementById("energyConsumeChart"),'dark'
            );
            let option = {
                color: ["#80FFA5", "#00DDFF"],
                backgroundColor: "rgba(0,0,0,0)",
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            backgroundColor: "#fff",
                        },
                    },
                },
                legend: {
                    fontSize: 12,
                    itemWidth: 12,
                    itemHeight: 12,
                    icon: "roundRect",
                    data: ["本月", "上月"],
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        boundaryGap: false,
                        data: ["研发部", "技术部", "综合部", "市场部", "财务部", "行政部"],
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                    },
                ],
                series: [
                    {
                        name: "本月",
                        type: "line",
                        stack: "Total",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                        },
                        data: [120, 132, 101, 134, 102, 150],
                    },
                    {
                        name: "上月",
                        type: "line",
                        stack: "Total",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                        },
                        data: [220, 182, 191, 234, 165, 149],
                    }
                ],
            };
            energyConsumeChart.setOption(option, true);
        },
        chartEnergyUsed () {
            let energyUsedChart = this.$echarts.init(
                document.getElementById("energyUsedChart"),'dark'
            );
            var option = {
                color: ["#00eaff", "#008aff", "#00ffa8", "#fddf35"],
                backgroundColor: "rgba(0,0,0,0)",
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                legend: {
                    fontSize: 12,
                    itemWidth: 12,
                    itemHeight: 12,
                },
                grid: {
                    left: "0",
                    right: "0",
                    top: "60px",
                    bottom: "15px",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: ["研发部", "技术部", "财务部", "行政部", "市场部"],
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                    },
                ],
                series: [
                    {
                        name: "标准量",
                        type: "bar",
                        emphasis: {
                            focus: "series",
                        },
                        data: [320, 332, 301, 334, 260],
                    },
                    {
                        name: "实际用量",
                        type: "bar",
                        stack: "Ad",
                        emphasis: {
                            focus: "series",
                        },
                        data: [360, 300, 340, 310, 230],
                    },
                    {
                        name: "节约量",
                        type: "bar",
                        stack: "Ad",
                        emphasis: {
                            focus: "series",
                        },
                        data: [0, 32, 0, 24, 30],
                    },
                    {
                        name: "超出量",
                        type: "bar",
                        stack: "Ad",
                        emphasis: {
                            focus: "series",
                        },
                        data: [40, 0, 39, 0, 0],
                    },
                ],
            };
            energyUsedChart.setOption(option, true);
        },
        handleClose () { },
        handleCheckAllChange (module) {
            this.checkAll = {
                pipe: false,
                check: false,
                saft: false,
                route: false,
                camera: false,
                device: false
            }
            if (this.cur_checkall == module) {
                this.checkAll[module] = false;
                this.cur_checkall = "";
                this.checkedItems[module] = [];
            } else {
                this.checkAll[module] = true;
                this.cur_checkall = module;
                Object.keys(this.checkedItems).forEach(key => {
                    if (key != module) {
                        this.checkedItems[key] = [];
                    }
                })
                this.checkedItems[module] = this.$data[module + "Options"];
            }

            this.handleThingJsChange(module)
        },
        handleCheckedItemsChange (module) {
            this.checkAll = {
                pipe: false,
                check: false,
                saft: false,
                route: false,
                camera: false,
                device: false
            };
            this.cur_checkall = module;
            Object.keys(this.checkedItems).forEach(key => {
                if (key != module) {
                    this.checkedItems[key] = [];
                }
            })

            if (this.checkedItems[module].length > 0 && this.checkedItems[module].length == this.$data[module + "Options"].length) {
                this.checkAll[module] = true;
            } else {
                this.checkAll[module] = false;
            }
            this.handleThingJsChange(module);
        },
        //筛选后在thingjs内对相关模型进行统一处理
        handleThingJsChange (module) {
            let that = this;
            this.selectDeviceModelIds = [];
            let checked = this.checkedItems[module];
            this.toThingJS("resetCampus", {});
            this.showDeviceList = false;
            this.showCameraList = false;
            this.showEnergyInfo = false;
            switch (module) {
                case 'device':
                    checked.forEach(item => {
                        this.getModeIds(item);
                    });

                    this.filterDeviceData = this.listDeviceData.filter(item => {
                        return this.selectDeviceModelIds.indexOf(item.modeId) != -1;
                    });

                    if (this.filterDeviceData.length > 0) {
                        this.showDeviceList = true;
                    } else {
                        this.showDeviceList = false;
                    }
                    break;
                case 'pipe':
                    this.toThingJS("showPipeInfo", { cate: checked });
                    if (checked.length > 0) {
                        this.showEnergyInfo = true;
                        this.$nextTick(() => {
                            this.chartEnergyConsume()
                            this.chartEnergyUsed()
                        });
                    } else {
                        this.showEnergyInfo = false;
                    }


                    break;
                case 'route':
                    this.toThingJS("showRouteInfo", { cate: checked });
                    break;
                case 'check':
                    this.toThingJS("showCheckInfo", { cate: checked });
                    break;
                case 'saft':
                    var safts;
                    if (checked.indexOf('saft_exting') != -1) {
                        safts = this.listDeviceData.filter(item => {
                            return item.modeId == '1422084265290870786';
                        });
                    } else {
                        safts = [];
                    }
                    this.toThingJS("showSaftInfo", { data: safts });
                    break;
                case 'camera':
                    this.showCameraList = checked.length > 0;

                    this.cameraData = this.listDeviceData.filter(item => {
                        return item.modeId == '1422084265290870791' && checked.indexOf(item.address) != -1;
                    });


                    if (this.cameraData.length > 0) {
                        this.cameraData.forEach(item => {
                            Api.getCameraPreviewUrl(item.firm).then(resp => {
                                item.previewUrl = resp.data.data.url
                                that.initVideo('video_' + item.id, resp.data.data.url);
                            });
                        });
                    } else {
                        if (this.videos.length > 0) {
                            this.videos.forEach(item => {
                                item.dispose();
                            });

                            this.videos = [];
                        }

                    }
                    this.toThingJS("showCameraInfo", { data: this.cameraData });
                    break;

            }

        },
        getAllDevices (modelId) {
            Api.listDevices({ modeId: modelId, limit: 1000, page: 1 }).then(resp => {

                this.listDeviceData = resp.data.list;
                
            })
        },
        sendDeviceToThingJS(){
            this.toThingJS("loadDeviceData",{data:this.listDeviceData});
        },
        getModeIds (cate) {
            if (cate.children && cate.children.length > 0) {
                cate.children.forEach(item => {
                    this.getModeIds(item);
                });
            } else {
                this.selectDeviceModelIds.push(cate.id);
            }
        },

        showDetailInfo (params) {
            console.log("ThingJS返回结果====>", params);
            var target = this.listDeviceData.filter(item => {
                return item.model3dId == params.model3dId;
            });

            if (target.length > 0) {
                this.selectDeviceData = target[0];
                this.showDeviceDetail = true;
            }

        },
        closeDetailInfo () {
            if (typeof (this.$refs.detaillist) != "undefined") {
                this.$refs.detaillist.$refs.table.setCurrentRow()
            }
            this.showDeviceDetail = false;
        },
        //场景处理
        handleChangeWeather (val) {
            this.weather = {
                qing: false,
                rain: false,
                snow: false,
            }
            if (this.cur_weather == val) {
                this.cur_weather = "";
                this.weather[val] = false;
                this.toThingJS("resetParticle", {});
            } else {
                this.cur_weather = val;
                this.weather[val] = true;

                switch (val) {
                    case 'qing':
                        this.toThingJS("resetParticle", {});
                        break;
                    case 'rain':
                        this.toThingJS("createRain", {});
                        break;
                    case 'snow':
                        this.toThingJS("createSnow", {});
                        break;
                }
            }
        },
        handleChangeSceneState (val) {
            this.sceneState = {
                day: false,
                night: false,
                time: false
            };
            this.closeTimer();
            if (this.cur_scene == val) {
                this.cur_scene = "";
                this.sceneState[val] = false;
            } else {
                this.cur_scene = val;
                this.sceneState[val] = true;

                switch (val) {
                    case 'time':
                        this.startTimer();
                        this.cur_time = new Date().getHours();
                        this.toThingJS("changeTime", { time: this.cur_time });
                        break;
                    case 'day':
                        this.toThingJS("changeTime", { time: 8 });
                        break;
                    case 'night':
                        this.toThingJS("changeTime", { time: 20 });
                        break;
                }
            }


        },
        handleExpandFloors (val) {
            this.toThingJS("setFloorExpand", { expand: val, distence: 5 });
        },
        handleExceptionDemo (val) {
            this.exception = {
                route: false,
                fire: false,
                device: false
            }
            if (this.exceptionType == val) {
                this.exceptionType = "";
                this.exception[val] = false;
                this.showDetailBox = false;
                this.showDetailBox2 = false;
                this.toThingJS("resetCampus", {});

            } else {
                this.exceptionType = val;
                this.exception[val] = true;

                switch (val) {
                    case 'route':
                        this.toThingJS("pipeExceptionDemo", { time: 20 });
                        // this.showDetailBox = true;
                        break;
                    case 'fire':
                        this.toThingJS("fireExceptionDemo", { time: 20 });
                        // this.showDetailBox2 = true;
                        break;
                    case 'device':
                        this.toThingJS("deviceExceptionDemo", { time: 20 });
                        // this.showDetailBox = true;
                        break;
                }
            }

        },
        handleTimeChange (val) {
            this.toThingJS("changeTime", { time: val });

        },
        handleChangeFloor (val) {
            var visible = this.selectFloor[val]?"in":"out";
            this.toThingJS("selectFloor", { floor: val,visible:visible });

        },
        handleRowClick (row) {
            console.log(row);
            this.dialogVisible = true;
        },
        rowstyle ({ row, rowIndex }) {
            let stylejson = {};
            if (row.weight === "高") {
                stylejson.backgroundColor = "#ffb0b0";
            } else if (row.weight === "中") {
                stylejson.backgroundColor = "#e2d84b";
            } else {
                stylejson.backgroundColor = "#fff";
            }
            return stylejson;
        },
        changeMainNav (nav) {
            this.current_sub = [];
            if (this.current != "" && nav == this.current) {
                this.current = "";
            } else {
                this.current = nav;
            }

        },
        changeSubNav (subnav) {
            this.current = "";
            if (this.current_sub.length > 0 && subnav.split("_")[0] != this.current_sub[0].split("_")[0]) {
                this.current_sub = [];
            }

            if (this.current_sub.indexOf(subnav) != -1) {
                var index = this.current_sub.findIndex(
                    (item) => item == subnav
                );
                this.current_sub.splice(index, 1);
            } else {
                this.current_sub.push(subnav);
            }
        },
        showRoomInfo (item) {
            this.current_sub = item.sn;
            this.toThingJS("selectRoom", { target: item });
        },
        showCameraInfo (item) {
            this.current_camera = item.sn;
            this.toThingJS("selectCamera", { target: item });
        },


        showDeviceInfo (item) {
            this.current_device = item.sn;
            this.toThingJS("showDeviceInfo", { data: item });
        },

        getAllUsers () {
            Api.listUsers().then(resp => {
                this.users = resp.data;
            });
        },
        getDeviceModals () {
            Api.treeDeviceModel().then(res => {
                this.deviceOptions = res.data;
            });
        },
        startTimer () {
            this.timer = setInterval(() => {
                let curHour = new Date().getHours();
                if (new Date().getMinutes() == 0) {
                    this.toThingJS("changeTime", { time: curHour });
                }

            }, 1000 * 60);
        },
        closeTimer () {
            clearInterval(this.timer);
        }

    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created () {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted () {
        // 接收ThingJS页面传送的数据
        window.addEventListener("message", (e) => {
            var data = e.data;
            console.log("监听ThingJS=====>", e);
            var func = data.cmd;
            var params = data.params;
            if(func == "loadingProgress"){
                if (this.$parent[func]) this.$parent[func](params);
            }else{
                if (this[func]) this[func](params);
            }
            
        });

        let that = this;
        this.startTimer();

        this.$nextTick(() => {
            this.$store.dispatch("PwdLogin", { username: 'dygc', password: '123456' }).then(() => {
                this.getAllDevices(null);
                this.getAllUsers();
                this.getDeviceModals();
            });
        });




    },
    destroyed () {
        this.closeTimer();
    },
};
</script>

<style lang='scss' scoped>
.video-swipper {
    position: absolute;
    right: 0;
    left:0;
    width: 780px;
    height: 120px;
    bottom: 50px;
    margin: auto;

    .swiper-container {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;

        .swiper-slide {
            text-align: center;
            font-size: 18px;
            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;

            background-color: #486ed8;
            padding: 5px;
            .video-js {
                height: 100%;
            }
        }
    }
    .custom-button-prev,
    .custom-button-next {
        position: absolute;
        width: 30px;
        height: 120px;
        color: #fff;
        text-align: center;
        line-height: 120px;
        top: 0;

        i {
            font-size: 24px;
        }
    }
    .custom-button-prev {
        left: -40px;
        background-image: linear-gradient(to left, #365cc4, transparent);
    }
    .custom-button-next {
        right: -40px;
        background-image: linear-gradient(to right, #365cc4, transparent);
    }
}
.detail-info {
    .device-img {
        padding: 10px 60px;
        img {
            width: 100%;
        }
    }
    .detail-item {
        display: flex;
        flex-flow: row nowrap;
        padding: 5px 0;
        border-top: 1px solid rgba(79, 113, 182, 0.9);
        label {
            flex: 0 0 70px;
        }
        p {
            margin: 0;
            flex: 1;
        }
    }
}
.module-filter {
    position: absolute;
    width: 200px;
    left: 10px;
    top: 20px;
    z-index: 999;
    .module-item {
        margin-bottom: 20px;
        .el-checkbox {
            display: block;
        }
        .devider {
            margin: 10px 0;
            border-bottom: 1px dashed transparent;
        }
    }
    h4 {
        margin: 0 0 10px 0;
        padding-bottom: 10px;
        border-bottom: 1px solid #9ea5b6;
    }
}
.h-line {
    width: 340px;
    .module-item {
        .module-item-wrapper {
            display: flex;
            flex-flow: row wrap;
            /deep/ & > .el-checkbox {
                flex: 0 0 60px;
            }
            /deep/ .el-checkbox-group {
                flex: 1;
            }

            /deep/ .el-checkbox {
                display: inline-block;
                margin: 2px;
                width: 60px;
                height: 34px;
                line-height: 34px;
                border: 1px solid rgba(119, 153, 225, 0.6);
                white-space: break-spaces;
                text-align: center;
                background-color:rgba(119, 153, 225, 0.3);

                .el-checkbox__input {
                    display: none;
                }
                .el-checkbox__label {
                    padding: 2px 5px;
                    line-height: 1;
                    color: #fff;
                    font-size: 12px;
                }
                &.is-checked {
                    background-color: #7599ff;
                    .el-checkbox__label {
                        color: #fff;
                    }
                }
            }
            /deep/ .el-checkbox.w4 {
                .el-checkbox__label {
                    padding: 5px 13px;
                    line-height: 1.1;
                }
            }
            /deep/ .el-checkbox.w6 {
                .el-checkbox__label {
                    padding: 5px 5px;
                    line-height: 1.1;
                }
            }
        }
    }
}
.scene-control {
    position: absolute;
    width: 330px;
    right: 20px;
    top: 20px;
    background-color: rgba(27, 55, 109, 0.7);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    padding: 10px;
    border-radius: 0;
    display: flex;
    color:#fff;
    .scene-item {
        flex: 0 0 100px;
        .el-checkbox {
            display: block;
            color:#fff;
            /deep/ &.is-checked .el-checkbox__label{
                color:#97cbff;
            }
        }
        
        .devider {
            margin: 10px 0;
            border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
        }
    }
    .time-slider {
        position: absolute;
        bottom: 2px;
        width: 140px;
        right: 15px;
        /deep/ .el-slider__runway {
            background: #fff;
        }
        /deep/ .el-slider__stop {
            background-color: #e8ebfc;
        }
        /deep/ .el-slider__marks-text {
            width: 30px;
            text-align: center;
        }
    }
    h4 {
        margin: 0 0 10px 0;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
}

.warning-messages {
    position: absolute;
    right: 400px;
    top: 20px;
    width: auto;
}

.energy-info {
    position: absolute;
    width: 330px;
    right: 20px;
    top: 225px;
    background-color: rgba(27, 55, 109, 0.7);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    padding: 10px;
    border-radius: 0;

    /deep/ .el-tabs__nav-wrap {

        &::after{
            background-color: rgba(27, 55, 109, 0.3);
        }
        .el-tabs__active-bar{
            background-color: #fff;
        }
        .el-tabs__item{
            color:rgba(255, 255, 255, 0.7);
        }
        .el-tabs__item.is-active{
            color:#fff;
        }
    }

    .energy-record {
        display: flex;
        flex-flow: row wrap;

        .energy-record-item {
            flex: 1 1 50%;
            text-align: center;
            padding: 10px;

            h3 {
                margin: 0;
                font-size: 20px;
                font-weight: bold;
                color:#fff;
            }
            p {
                margin: 5px 0 0 0;
                font-size: 12px;
                color:rgba(255,255,255,0.7)
            }
        }
    }

    .energy-chart {
        height: 200px;
        padding: 15px 0;
        border-top: 1px solid rgba(27, 55, 109, 0.5);
    }
}

.textAndPictureMarker .text-marker {
    background-color: #e8ebfc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 5px;
    position: relative;
    border-top: 3px solid #3a8ee6;
}
.textAndPictureMarker .text-marker::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid #e8ebfc;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    margin: auto;
}
.text-marker .text-item {
    width: 200px;
    display: flex;
    flex-flow: row nowrap;
    font-size: 12px;
    margin-bottom: 1px;
}
.text-marker .text-item label {
    flex: 0 0 70px;
    background-color: #cae0f7;
    padding: 2px;
}
.text-marker .text-item span {
    flex: 1;
    padding: 2px;
}
.btn__energy-close{
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    border: 0 none;
    color: #fff;
    padding: 15px;
    cursor: pointer;
    z-index: 999;
}
/deep/ .el-table {
    background-color: transparent;

    th.el-table__cell {
        background-color: transparent;
        color: #fff;
    }
}
/deep/ .el-table .cell {
    padding: 0 5px !important;
}
/deep/ .el-table--mini .el-table__cell {
    padding: 3px 0;
}
/deep/ .el-table td.el-table__cell,
/deep/ .el-table th.el-table__cell.is-leaf {
    border-color: rgba(0, 0, 0, 0.1);
}
/deep/ .el-table th.el-table__cell {
    background-color: rgb(57, 98, 151);
}
</style>