<template>
    <div class="device-list">
        <div class="device-list-wrapper">
            <div class="device-filter">
                <div class="device-type">
                    <el-select v-model="select_filter" style="width:100%;" size="small" placeholder="请选择" @change="handleFilterSelect">
                        <el-option v-for="item in filters" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="device-search">
                    <el-input placeholder="关键字" v-model="keyword" size="small" class="input-search">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
            </div>
            <div class="device-data">
                <el-table :data="data" ref="table" style="width: 320px" :highlight-current-row="highlight" :height="tableHeight" @row-click="handleRowClick">
                    <el-table-column prop="name" label="设备名字"></el-table-column>
                    <el-table-column prop="runStatus" label="状态" width="60" align="right">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.runStatus==1" size="mini">正常</el-tag>
                            <el-tag type="info" v-else-if="scope.row.runStatus==2" size="mini">异常</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import { log } from 'video.js';

// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import { mapGetters } from 'vuex'
export default {
    name: 'DeviceList',
    props: {
        dataSource: Array
    },
    components: {

    },
    // 定义属性
    data () {
        return {
            data: this.dataSource,
            select_filter: "",
            keyword: "",
            tableHeight: "",
            current: null,
            highlight: true,
            filters: [
                {
                    value: '',
                    label: '全部'
                },
                {
                    value: '1',
                    label: '正常运行'
                },
                {
                    value: '2',
                    label: '异常'
                }
            ]
        }
    },
    // 计算属性，会监听依赖属性值随之变化
    computed: {

    },
    // 监控data中的数据变化
    watch: {
        dataSource (val) {
            this.data = val;
        }
    },
    // 方法集合
    methods: {
        handleNodeClick (row) {
            console.log(row);
        },
        handleFilterSelect (val) {
            console.log(val);
            if (val) {
                var temp = this.dataSource.filter(item => {
                    return item.runStatus == val;
                });
                this.data = temp;
            } else {
                this.data = this.dataSource
            }
        },
        handleRowClick (row, column, event) {
            this.toThingJS("showSelectThing", { data: row });
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created () {
        //this.tableHeight = 
        var clientHeight = `${document.documentElement.clientHeight}`;
        this.tableHeight = clientHeight - 595;

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted () {
        let that = this;
        window.onresize = function temp () {
            var clientHeight = `${document.documentElement.clientHeight}`;
            that.tableHeight = clientHeight - 630;
            that.$refs.table.doLayout();
        };
    },
    beforeCreate () { }, // 生命周期 - 创建之前
    beforeMount () { }, // 生命周期 - 挂载之前
    beforeUpdate () { }, // 生命周期 - 更新之前
    updated () { }, // 生命周期 - 更新之后
    beforeDestroy () { }, // 生命周期 - 销毁之前
    destroyed () { }, // 生命周期 - 销毁完成
    activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang='scss' scoped>
.device-list {
    position: absolute;
    left: 10px;
    top: 490px;
    bottom: 10px;
    background-color: rgba(27, 55, 109, 0.7);
    border-radius: 0;
    padding: 10px;
}
.device-list.h-line-list {
    left: 10px;
}
.device-list-wrapper {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    .device-filter {
        flex: 0 0 70px;
        /deep/ .el-select{
                .el-input__inner{
                    border-radius: 0;
                    background-color: rgba(0,0,0,0.5);
                    border:0 none;
                    color:#ccc;
                }
            }
        .device-search {
            margin-top: 5px;
            width: 100%;
            
            /deep/ .input-search {
                .el-input__inner {
                    width: 287px;
                    border: 0 none;
                    border-radius: 0;
                    line-height:33px;
                    height:33px;
                    background-color: rgba(0,0,0,0.5);
                    color:#fff;
                }
                .el-input-group__append {
                    border-radius: 0;
                    border: 0 none;
                    padding: 0;
                    width:33px;
                    background-color: transparent;
                    .el-button {
                        padding: 10px;
                        text-align: center;
                        border-radius: 0;
                        border: 0 none;
                        background-color: rgba(0,0,0,0.5);
                        color:#fff;
                        margin:0;
                    }
                }
            }
        }
    }
    .device-data {
        flex: 1;
        margin-top: 5px;
        /deep/ .el-table {
            color:#fff;
            &::before{
                background-color: rgba(27, 55, 109, 0.7);
            }
            col[name='gutter']{
                width:7px !important;
            }
            tr{
                background-color: transparent;
            }
            .el-table__cell {
                padding: 7px;
            }
            .el-table__cell.gutter:last-of-type{
                width:7px !important;
                padding:0 !important;
                display: table-cell !important;
                border: 0 !important;
            }
        }
        
        /deep/ .el-table__body tr.current-row > td.el-table__cell {
            background-color: #9dc9fc;
        }
        /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
            background-color: rgba(116, 156, 236, 0.3);
        }

        /deep/ .el-table .el-table__body-wrapper::-webkit-scrollbar {
            width: 7px;
            height: 7px;
            background-color: transparent;
        }
        /*定义滚动条轨道 内阴影+圆角*/
        /deep/ .el-table .el-table__body-wrapper::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 0;
            background-color: rgba(116, 156, 236, 0.3);
        }
        /*定义滑块 内阴影+圆角*/
        /deep/ .el-table .el-table__body-wrapper::-webkit-scrollbar-thumb {
            border-radius: 0;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: rgb(151, 184, 255);
        }
    }
}
</style>