/**
 * 模块：用户相关接口
 * 作者：Journey Long
 */

import request from '@/utils/request'
import { method } from 'lodash';
import qs from 'qs'

export function login(data) {
    return request({
        url: "/custom/sas/login",
        method: "post",
        data: data,
        headers: { "isToken": false }
    });
}

//查询建筑信息
export function listBuilding() {

    return request({
        url: '/building.json',
        method: 'get'
    })
}

/**
 * 获取所有设备信息
 * @returns
 */
export function listDevices(params) {
    return request({
        url: '/custom/device/info/list',
        method: 'get',
        params: params
    })
}

export function treeDeviceModel() {
    return request({
        url: "/custom/device/mold/info",
        method: "get"
    });
}

/**
 * 获取当前企业所有用户信息
 * @param {用户JSON对象} 
 * @returns
 */
export function listUsers() {
    return request({
        url: '/custom/sys/users/getAllUser',
        method: 'get'
    })
}

//获取设备能耗信息
export function infoEnergy(deviceId) {
    return request({
        url: '/custom/device/energy/info/' + deviceId,
        method: 'get'
    });
}

/**
 * 获取生成记录 
 * 参数 分页（ limit：多少个，page：第几个） 
 * 条件查找(type 0 按年 1按月 2按日) 
 * startTime 日期 {年 ：2020 月：2020-6 日：2020-6-9 
 * endTime 日期 {年 ：2020 月：2020-6 日：2020-6-9 } ,
 * 公司Id:companyId,
 * rId：区域ID/设备ID/生产线ID，
 * rType:0 按区域 1按设备 2生产线
 * 
 * @param {*} params 
 * @returns 
 */
export function listCapacities(params) {
    return request({
        url: 'custom/energy/data/capacityList',
        method: 'get',
        params: params
    });
}

/**
 * 获取设备运行记录
 * @param {productDevId:String,page:Number,limit:Number} params 
 * @returns 
 */
export function listRunRecords(params) {
    return request({
        url: 'custom/device/runIng/list',
        method: 'get',
        params: params
    });
}

/**
 * 获取设备运维记录
 * @param {devId:String,page:Number,limit:Number} params 
 * @returns 
 */
export function listDevOpsRecords(params) {
    return request({
        url: '/custom/device/ops/list',
        method: 'get',
        params: params
    });
}

/**
 * 获取设备关联表计
 * @param {*} devId 
 * @returns 
 */
export function listRelationMeter(devId) {
    return request({
        url: '/custom/device/relation/delete',
        method: 'get',
        params: { devId: devId }
    });
}

//获取视频预览地址
export function getCameraPreviewUrl(cameraIndexCode) {
    return request({
        url: '/camera/getCameraPreviewURL',
        method: 'get',
        params: { cameraIndexCode: cameraIndexCode, protocol: "hlss", streamType: 0 }
    });
}