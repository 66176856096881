import vue from 'vue'
import vuex from 'vuex'
import user from './modules/user'
import device from './modules/device'
import getters from './getters'

vue.use(vuex)
export default new vuex.Store({
    modules: {
        user,
        device
    },
    // state
    state: {


    },
    /*荷载：更改store中状态的唯一方法就是提交mutation（注意：mutations都是同步事务）*/
    mutations: {


    },
    /*异步活动：类似于mutations，但是actions提交的是mutation而不是直接改变状态（注意：actions可以包含任意异步操作）*/
    //使用场景：在异步方法中，通过mutations中的方法来改变状态
    actions: {

    },
    /*计算*/
    getters: getters
})