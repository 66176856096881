<template>
  <div class="ui-box" >
    <fieldset :style="curStyle">
      <legend class="ui-box-title"><i :class="icon" v-if="icon"></i> {{this.title}}<u :style="aniDelay"></u></legend>
      <div class="ui-box-content">
        <slot></slot>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: 'UiBox',
  props: {
    title: String,
    icon: String,
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "300px"
    }
  },
  data() {
    return {
      ani_delay:0,
    }
  },
  computed:{
    curStyle(){
      return {
        width:this.width,
        height:this.height
      };
    },
    aniDelay(){
      return {
        "animation-delay":this.ani_delay+"s",
        "-webkit-animation-delay":this.ani_delay+"s"
      }
    }
  },
  created(){
    this.ani_delay = parseInt(Math.random()*10)/10;
  },
  mounted(){
    //console.log(app);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.ui-box{
  background-color: rgba(27,55,109,0.7);
  padding:6px;
  margin-bottom:5px;
  width:100%;
}
.ui-box fieldset{
    margin: 0;
    padding: 0;
    position: relative;
    color:#fff;
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: rgba(255,255,255,0.2);

    .ui-box-title{
      padding:5px 0;
      font-weight: bold;
      padding-right:12px;
      u{
        position: absolute;
        width:30px;
        height:3px;
        right:3px;
        top: 11px;
        background-color: #ffbc42;
        animation: title-move-repeat 1s linear infinite alternate;
        -webkit-animation: title-move-repeat 1s linear infinite alternate;
      }
    }
    .ui-box-title::before{
      content:'';
      position: absolute;
      right:80px;
      top:11px;
      width:3px;
      height:3px;
      background-color: rgba(255,255,255,0.3);
    }
    .ui-box-title::after{
      content:"";
      position: absolute;
      right:0px;
      top: 9px;
      width: 1px;
      height: 8px;
      background-color: rgba(255,255,255,0.3);
    }
    

    .ui-box-content{
      width:100%;
      height: 100%;
    }
}
</style>
