import axios from 'axios'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'

axios.defaults.crossDomain = true;
axios.defaults.headers['Content-Type'] = 'application/json'
    // 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分 process.env.VUE_APP_BASE_API, http://2z73n10107.zicp.vip http://192.168.0.231:7000/sys-center-v3-dev
    baseURL: "https://2z73n10107.zicp.vip",
    // 超时
    timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改 Bearer
    }
    return config
}, error => {
    console.log('err：' + error);
    return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        const code = res.data.code;
        if (code == 10000) {
            return res.data;
        } else {
            Message.error({
                message: res.data.message
            });
        }
    },
    error => {
        console.log('err：' + error);
        let { message } = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }

        Message.error({
            message: message
        });
        return Promise.reject(message);
    }
)

export default service