<!--
页面名称：404.vue
页面描述：该页面为scm管理系统-404页面
开发人员：jss
开发时间：2021-03-15
其他说明：
XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
-->
<template>
  <div class="forbidden">
    <!-- 登录 -->
    <div class="container container-password">
      <img class="header-img" :src="img_404" />
    </div>
  </div>
</template>

<script>

import forbiden from "../../../public/images/404.png";
export default {
  name: "Forbidden",
  data () {
    return {
      img_404: forbiden,
    };
  },
  mounted () {
  },
  methods: {


  }
};
</script>

<style scoped>
.forbidden {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
